import React, { useState, useEffect } from 'react';
import {NavbarTop} from './ui_components/navbar_top';


function AboutWebsite() {
    return (
      <div>
        <NavbarTop />
        <div className='pt-20 bg-gray-100'></div>
        <div className="bg-gray-100">
      <div className="container mx-auto py-8 px-4">
      
<div className='flex flex-row  
          text-center justify-center items-center'>
   
      <img className='w-1/2 '   
          src="https://public-items-1.s3.ap-south-1.amazonaws.com/codezym/seo_images/codezym-home-page-3.jpg"
      />
    </div> <br /><br />
        <h1 className="text-3xl font-bold text-center mb-8">
          What is CodeZym all about ??
        </h1>
        <div className="prose max-w-none">
          <p className="mb-4">
            CodeZym is a place for you to solve top low level design interview questions using the most common design patterns. <br />

            {
              /*
            CodeZym is a platform to practice machine coding of object-oriented design interview questions in a multi-threaded environment.
            */}
          </p>
          <p>I built CodeZym because there are tons of great websites like LeetCode to practice for  <br />  DS & Algo rounds but I didn't find any good websites where I can practice low level design interview questions.  </p> <br />

          <p className="mb-4">
            <br />
            In a software engineering interview, you typically encounter three types of technical rounds:
          </p>

          <ol className="list-decimal pl-8 mb-4 ">
            <li className="pb-4">
              <strong>Data Structures and Algorithms Round:</strong>
              <br />
              This round consists of 1 or 2 coding questions to be solved within 45 minutes.
              <br /> It doesn't require the use of design patterns, and the environment is single-threaded.
              
            </li>
            <li className="pb-4">
              <strong>Low-Level Design Round (Object-Oriented Design):</strong>
              <br />
              In this round, you will be given 1 coding question to be completed within 90 to 120 minutes.
              <br /> Your code should work correctly in a multithreaded environment and preferably utilize design patterns.
              <br />
            </li>
            <li className="pb-4">
              <strong>High-Level Design Round (System Design):</strong>
              <br />
              This round involves a discussion on a specific design question and typically lasts for 45 to 60 minutes.
            </li>
          </ol>

          <div className="mt-8">
          <div className="bg-gray-200 p-6 rounded-lg shadow-md">
            <p className="mb-4">
              At CodeZym, your solutions are tested in a multithreaded environment,<br /> making it essential to use thread-safe data structures and ensure proper synchronization.
            </p>
            <p className="mb-4">
              The questions on CodeZym primarily focus on Low-Level Design.<br /> For instance, you may be asked to design a parking lot or an order inventory management system.
            </p>
            <p className="mb-4">
              Questions are specifically designed to encourage the use of design patterns.<br /> Not using design patterns may result in complex code that is difficult to read and debug.
            </p>
            <p>
              By practicing on CodeZym, you gain valuable real-time machine coding interview experience.<br />
              This will help you refine your skills and perform better in software engineering interviews.
            </p>
          </div>
        </div>

        

        </div>
      </div>
    </div>
      </div>
    );
  }

  export default AboutWebsite;