
/** returns a clickable link for url with hover properties,  
 * link becomes blue with a light blue background on hover
*/
function LinkUI({url, text=""}){
    return (
        <a href={url}
        className="text-gray-600 underline
        cursor-pointer px-4 py-2 rounded-full
        hover:text-blue-600 hover:bg-blue-100
        font-medium font-mono"
         target="_blank">
          {text || url}    
        </a>
    );
}

/**
 * show back text with underline which turns blue on hover 
 */
function LinkUIBasic({url, text=""}){
    return (
        <a href={url}
        className="text-gray-600 underline
        cursor-pointer px-4 py-2 rounded-full
        hover:text-blue-600 
        font-medium font-mono"
         target="_blank">
          {text || url}    
        </a>
    );
}

function LinkUIBlueBold({url, text=""}){
    return (
        <a href={url}
        className="text-blue-600 
        cursor-pointer px-2 rounded-full
        hover:text-blue-700 hover:underline
        hover:font-bold
         font-mono font-medium text-xl"
         target="_blank">
          {text || url}    
        </a>
    );
}

export {LinkUI, LinkUIBasic, LinkUIBlueBold}