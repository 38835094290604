import React, { useState, useEffect, Children } from 'react';
import { errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import {NavbarTop} from '../ui_components/navbar_top';
import { YoutubeVideoDisplay } from '../ui_components/youtube_video_display';
import { LinkUI } from '../ui_components/common_links';
import { txb, txbb, txhb, txhbb } from '../ui_components/text_style_components';
import { ExpandCloseTab } from '../ui_components/common_ui_components';
import { isPremiumUser } from '../login/auth_utils';
import { VisitPremiumSection } from '../premium/premium_discounts';

function ParkingLotQ01UI(){
  return(
  <div className="flex flex-col items-center justify-center">
  <div className='flex flex-row items-center justify-center'>
  <div className='mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design a Parking Lot")} <br />
  <LinkUI url="https://codezym.com/question/1" />  <br />  <br />
  Parking Lot design is sort of the {txbb("Hello World")} of low level design questions. <br />
  Most people start their LLD interview preparation with this question. <br /> <br />
  {txbb("Python")} solution video on the right takes you through the core features  <br /> like  park, remove vehicle, search vehicle and also teaches you <br /> how to clearly explain your design to interviewer step by step <br /> in a 45-60 minute LLD interview.   
  <br /> <br />
  For {txbb("Java")}, we have to implement our solution for a {txbb("multi-threaded")}  <br /> environment using locking and thread safe data structures. <br />
    Video on the right show a simple solution using {txbb('synchronized')}
    keyword  <br />
 which is the popular locking mechanism in Java. <br /> <br />
 However, using {txbb('synchronized')} or any other lock is simple but not efficient 
  <br /> as it locks out other threads from doing write operations <br /> concurrently. <br /> 
   <br /> <br />
  </div>
  <div className="flex flex-col items-center justify-center">
  <div><YoutubeVideoDisplay videoId='7FPnJppdIZM' /></div>
  <br /> 
  <div><YoutubeVideoDisplay videoId='817XIgbH2yk' /></div>
  </div>
  </div>  {/* practice problem row ends */}

  {/* Efficient solution row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div><YoutubeVideoDisplay videoId='JfMciz7lC3M' /></div>

  <div className='mx-12'>
  
 Video on the left shows a more efficient solution 
 using a thread safe list <br /> {txbb(' ConcurrentLinkedDeque')} 
 to store free spots on each floor. <br />
 It ensures that on each floor rather than using brute force <br />
 to find a free spot, we can add/remove parking spots in {txbb('O(1)')}
   <br /> <br />
  </div>
  
  </div> 

  </div>
    );
}

function Q04InventoryManagementUI(){
return(
<div className="flex flex-col items-center justify-center">

<div className='flex flex-row items-center justify-center'>
<div className="flex flex-col ">  
{txb("4. Design an order and inventory management system")}
 
{LinkUI({url:"https://codezym.com/question/4"})}  <br />
An inventory management system of a  <br /> simple e-commerce platform,  <br />
 needs to have the capability of  <br />
handling sellers, products and orders.<br />

Inventory is number of items of a    <br />
particular product in a seller's warehouse.  <br />

</div>
&nbsp;&nbsp;&nbsp;
<div>
  <br /><br />
<YoutubeVideoDisplay videoId='WR89ZcPvNY8' />
</div>
</div>



</div>
);
}

function Q03CustomerIssueResolutionUI(){
  return(
  <div className="flex flex-col items-center justify-center">
  
  <div className='flex flex-row items-center justify-center'>
  <div>
    <br /><br />
  <YoutubeVideoDisplay videoId='03TaNq9_dXs' />
  </div>

  &nbsp;&nbsp;&nbsp;

  <div className="flex flex-col ">  
  {txb("3. Design a customer issue resolution system")}
   
  {LinkUI({url:"https://codezym.com/question/3"})}  <br />
  Customer support is nothing but a group of agents <br />
  with skills for solving a set of issues.<br /><br />

Customer Issues can be classified into multiple types <br />
like order delayed, Payment Related etc. <br />
A customer issue resolution system is used to <br />
assign customer's issues to agents.<br />
  
  </div>
  </div> 
  </div>
  );
  }

  function Q07ParkingLotStrategyPatternUI(){
    return(
<div className='flex flex-col items-center justify-center'>
  <div className='flex flex-row'>
  <div className='mx-8'>
  {txb("Design a Parking Lot using Strategy Pattern")}  <br />  
  <LinkUI url="https://codezym.com/question/7" />  <br />   <br />  
  We revisit the Parking Lot design again. <br />
  However, in this question we have to implement <br />
   two different parking strategies,  <br /> <br />

    So you should use strategy pattern here. <br /> <br />
    {txbb("Python")} solution video on the right takes you  <br />
     through the core features like  <br />  park, remove vehicle, search vehicle and it also <br /> 
     teaches you  how to clearly explain your design <br />
     to interviewer step by step <br /> in a 45-60 minute LLD interview.   
  <br /> <br />

     {txbb('Python')} video has 
     slightly better explanation  <br />
     than the {txbb('Java')} video below it.

   <br /> <br />
   </div>
  <div className='flex flex-col items-center justify-center'>
    <YoutubeVideoDisplay videoId='ZIK44dj56fk' />
    <br /><br />
    <YoutubeVideoDisplay videoId='fi_IWW1Ay0o' />
    </div>
  </div> 

      </div>

    );
  }

function SampleFunctionUI(){
    return(
    <div className="flex flex-col items-center justify-center">
    
    <div className='flex flex-row items-center justify-center'>
    
    </div>
    
    </div>
    );
}

export {ParkingLotQ01UI, Q04InventoryManagementUI, Q03CustomerIssueResolutionUI, Q07ParkingLotStrategyPatternUI}