import React, { useState, useEffect, Children } from 'react';
import { errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import {NavbarTop} from '../ui_components/navbar_top';
import { YoutubeVideoDisplay } from '../ui_components/youtube_video_display';
import { LinkUI } from '../ui_components/common_links';
import { txb, txbb, txhb, txhbb } from '../ui_components/text_style_components';
import { ExpandCloseTab } from '../ui_components/common_ui_components';
import { isPremiumUser } from '../login/auth_utils';
import { VisitPremiumSection } from '../premium/premium_discounts';
import { ParkingLotQ01UI, Q03CustomerIssueResolutionUI, Q04InventoryManagementUI, Q07ParkingLotStrategyPatternUI } from './prep_plan_helper_ui_classes';

function FirstPreparationPlan() {
   const prefix="roadmap1day"
   const [isPremium, setPremium] = useState(isPremiumUser());
    useEffect(() => {
    }, []) // empty bracket means there are no dependencies which will make useEffect re run
    
    return (
    <div className = "w-full items-center justify-center">
      <NavbarTop />
     <div className='flex flex-col  mx-6 w-4/5 items-center justify-center text-lg'>
    
      <h2 className='text-2xl font-mono text-center mt-24 mb-8 font-semibold m-6
      bg-blue-100 px-6 py-3 text-blue-600 rounded-t-md '>
         7 Days Preparation Plan  </h2> 
     <Introduction /> <br />  

     {/* buy premium div starts !isPremium . 
     I am making premium page free, it will act as motivation to buy premium instead */} 
     { false  &&
        <div className="flex flex-col w-3/4 justify-center mt-4 
        text-2xl text-gray-600 font-medium text-center
         bg-yellow-200 p-8 ">
         <div className=' text-xl'> 
           Only Premium Members  <br />
           Can access Complete Preparation Plan
           
         </div>
         <br />  <br />
         <VisitPremiumSection />
         </div>
        
     } {/* buy premium div ends */}

     {/* daywise schedule div starts */}
    { <div className='felx felx-col items-center justify-center text-lg '>
     <ExpandCloseTab title="Day 1 - Warming Up" 
        storageKey = {prefix+"1"} children={Day1()} />

      <ExpandCloseTab title="Day 2 - Strategy Pattern" 
        storageKey = {prefix+"2"} children={DayStrategyPattern()} /> 

      <ExpandCloseTab title="Day 3 - Observer Pattern" 
        storageKey = {prefix+"3"} 
        children={DayObserverPattern()} />  

      <ExpandCloseTab title="Day 4 - Factory and Singleton Pattern" 
        storageKey = {prefix+"4"} 
        children={DayFactoryAndSingletonPattern()} /> 

      <ExpandCloseTab title="Day 5 - FlyWeight Pattern" 
        storageKey = {prefix+"5"} 
        children={DayFlyweightPattern()} /> 

      <ExpandCloseTab title="Day 6 - State Pattern" 
        storageKey = {prefix+"6"} 
        children={DayStatePattern()} /> 
      
      <ExpandCloseTab title="Day 7 - Multi-threading" 
        storageKey = {prefix+"7"} 
        children={DayMultithreadingAndStrategyPattern()} />

     <br /> 
     <div className='text-2xl font-semibold font-mono
      text-blue-600 bg-blue-50 px-6 py-3 text-center '>
      Best of Luck
    </div>
    <br /> 
    
    <div className='flex flex-row w-full 
    text-center justify-center items-center'>
    <img className='w-1/2'
    src="https://public-items-1.s3.ap-south-1.amazonaws.com/codezym/seo_images/preparation-roadmap-5.jpg"
      />
    </div>
    </div> 
   }
    {/* daywise schedule div ends */}

     <br />  <br /><br />
     </div>
     </div>
  );
}

function Introduction(){
  return (
    <div className='flex flex-col w-full items-center justify-center leading-6'>
    <div className='flex flex-row items-center justify-center'>
    <div className='font-medium font-mono '>
    
    {
      /*
      This is a roadmap to get you ready for your  <br />
    Low Level Design Interviews. <br />  <br />
      */
      <div> 
        
       This roadmap will specifically concentrate on    <br />
       getting you ready for {txbb("Low Level Design Interviews")} <br /> <br /> 
       
          
<p>{txb('Languages:')} As of now {txbb('Java')} and {txbb('Python')}
   are supported.   <br /></p><br />

       We {txbb("don't")} intend to cover the comprehensive   <br />
       Low Level Design for all the components and     <br />
       hundreds of classes in large systems like    <br />
       Parking Lot, Food Ordering app and other systems.  <br /> <br /> 
       

      1. We will specifically deep dive into the     <br />
       &nbsp;&nbsp; core features of questions which interviewers    <br />
       &nbsp;&nbsp; expect to discuss with you in a LLD interview <br /> <br />

       2. YouTube video solutions will also teach you how to  <br />
       &nbsp;&nbsp; explain your solution to the interviewer step by step. <br /><br /> 
      {
       /*
       Both the theory and YouTube video solutions will   <br />
       specifically deep dive into the core features    <br />
       for the problem statement which interviewers will   <br />
       expect to discuss with you in a LLD interview. <br />  <br />
    */
    } 
       
      </div>
    }
    
   
    {/**  
    Some questions are free, but rest are premium. <br />
     So go ahead and
    <LinkUI url="/premium" text="Buy Premium" />
    Its cheap and totally worth it. <br />
  */}
    
    </div>
    <div className=' flex flex-col mt-4 m-6 ml-4'>
    
    <YoutubeVideoDisplay videoId="LLIPmP6p4RE" 
    /> 
    <br /> <br />
    <YoutubeVideoDisplay videoId="rzbEsMn-roE" 
    /> 
    
    </div>
  
    </div>
    <div className='flex flex-row items-center justify-center'>
    <div className='font-medium font-mono '>
    <div className='bg-yellow-200 rounded-lg p-4 px-16 py-6'>
    If you have an interview {txbb("tomorrow,")} then only read theory    
    from below. <br /> In {txbb("an hour")} you will know about  different type
    of questions and <br /> design patterns used to solve them.
    </div>
    <br />
      </div>
    </div>  
    
    <div className='flex flex-row items-center justify-center'> 
    <div className=' flex flex-col mt-4 m-6 mr-8'>
    <YoutubeVideoDisplay videoId="W0OHFKXxy3o" 
    />  
    </div>
    &nbsp;  &nbsp;
    <div className='font-medium font-mono '>
   {/* If you can devote more time like {txbb("4-5 hours")} then <br />watch all  the design pattern videos as well.
    <br /> <br />
    If you can spare even more time, then complete this <br />  whole roadmap and practice all questions. 
    <br /> <br />
    */}
     {/* We will start with some short videos and simple questions
      <br /> to get you in the  preparation mode and 
       then <br />  one by one cover most commonly asked topics. 
       <br /><br /> If you are not getting interview calls,
       <br /> then watch the video on the right for advice.
      <br /> <br />
     */}
      <div >
    {txbb("Plan")} is to start each day with a few short videos <br /> 
    to get you in preparation mode and then do<br /> one coding question covering one or more design patterns.
    </div>
    {/* <br /> <br />
     <i className='text-gray-700 text-sm'>If you understand Hindi then I recommend you to watch the Hindi videos <br />
     you may find explanation a bit better in them. </i>
    */}
      <br />
    </div>
    </div>


    </div>
    );
}

function Day1(){
    return (
    <div className='flex flex-col w-full items-center justify-center'>
      
    {/*first row starts */}  
    <div className='flex flex-row items-center justify-center'>
    <div className='mr-6'>
        There are {txbb('two types')}
         of low level design interview formats: <br />
        1. 75 to 90 minutes of machine Coding <br />
        2. 45-60 minutes of face to face discussion <br />
        <br />
       Watch the Youtube Video on the right for details. 
    </div>
    <div className=' flex flex-row mt-4 m-6'>
    <YoutubeVideoDisplay videoId="dVjd6gFUEsA" 
    />
    
    <YoutubeVideoDisplay videoId="br-3o1i3qLI" 
     />
    </div>
    </div>  {/*first row ends */}
  

 {/* ---- importance of coding row starts ------- */
  <div className='flex flex-row items-center justify-center'>
  <div className=' flex flex-col mt-4 mx-6'>
  <YoutubeVideoDisplay videoId="hWac3sK5BBo" 
  />
  <YoutubeVideoDisplay videoId="S5GUPErF-iw" 
  />
  </div>
  <div className='mx-6'>
    <p className='font-medium font-mono'>Following are the 
         {txbb(' three things')} interviewer is looking for</p>
        1. How you break your solution in multiple classes <br />
        2. Usage of design patterns to solve the problem <br />
        3. How you handle multithreading <br /> <br />
  Try to complete Coding for questions even if  <br /> 
    you have to watch the solution multiple times, 
    <br /> because that will make you comfortable with  <br /> 
     the different data structures <br /> especially 
      the thread safe data structures and edge cases 
     <br /> which will be useful when interviewer tweaks the question a little.
    <br />  <br />
  </div>
  
  </div>
     
 /* importance of coding row ends */
 }
<br />
    {/*second row starts */}  
    <div className='flex flex-row items-center justify-center'>
   {/* <div className=' flex flex-row mt-4 mx-6'>
    <YoutubeVideoDisplay videoId="YMAwgRwjEOQ" 
    /> 
    </div> */}
    <div className='mx-6'>
    These are the most common design patterns in LLD interviews.  <br />
        {txbb('Strategy,')}  {txbb('Factory,')}    
         {txbb('Singleton')} and {txbb('Observer')} <br />

        
        
       We will practice them first and them move on to other design patterns.
    </div>
    
    </div>  {/*second row ends */}
<br /> 

    {/* practice problem row starts */}  
   
   {/* <div className='flex flex-row items-center justify-center'>
    <div className='mx-12'>{txbb("Practice Problem")}  <br />
    {txb("Design a Hit Counter/Webpage Visits Counter - Multi-Threaded")} <br />
    <LinkUI url="https://codezym.com/question/6" />  <br />
    This is an easy question, yet it gives you a taste of <br />
    basic data structures to use in a multithreaded environment. 
    <br /> This makes it an ideal question to put you in preparation mode for <br /> your low-level design interviews. <br /> <br />
    </div>
    <div><YoutubeVideoDisplay videoId='jU7I2-jWJ8k' /></div>
    </div>  
    */}
    <ParkingLotQ01UI />
    {/* practice problem row ends */}

    </div>
    );
}


function DayStrategyPattern(){
  return (
  <div className='flex flex-col w-full items-center justify-center'>
  {/*first row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mr-6'>
    {txhbb('Strategy Design Pattern')} <br /> <br />

    Strategy Pattern is used when we need different algorithms 
    <br /> for the same functionality.  Some examples are : <br />
    
      1. Different moves in chess: straight, diagonal, 2+1 move <br />
      2. different ways to order list of items e.g. by price, rating, most ordered etc  
      
  </div>
  <div className=' flex flex-row mt-4 ml-6'>
  <YoutubeVideoDisplay videoId="R_AadNfw0k8" 
  />
    
  <YoutubeVideoDisplay videoId="l70m-wRXtcc" 
  />
  
 </div>
  </div>  {/*first row ends */}
 <br /> <br />
  {txbb("Practice Problem")}  <br />

  {/* practice problem row starts */}  
  <Q07ParkingLotStrategyPatternUI />
   {/* practice problem row ends */}
 
  </div>
  );
}

function DayMultithreadingAndStrategyPattern(){
  return (
  <div className='flex flex-col w-full items-center justify-center'>

  {/*first row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mr-6'> <br /> 
    {txhbb('Multi-Threading')} <br /> <br />
    In LLD interviews, after design patterns, discussion moves on to multi-threading. <br /> 
    For these question you have to use synchronization, locks and thread safe data structures. <br /> <br />
    If you are using {txbb("Java")} then you have access to thread safe data structures  <br /> like  AtomicInteger, ConcurrentHashMap, ConcurrentLinkedDequeue etc.
     <br />  <br />
     <div className=''>{txbb("Practice Problem")}  </div>
      
  </div>
  </div>  {/*first row ends */}

  <Q04InventoryManagementUI />
  <br /> <br />
  <Q03CustomerIssueResolutionUI />

  <br /> <br /> <br />

    
  </div>
  );
}

function DayFactoryAndSingletonPattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  
  {/*factory row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mr-6'> <br />
    {txhbb('Factory Design Pattern')} <br /> <br />

    Factory Pattern is a creational design pattern and  <br /> 
    it is used to decouple  object's creation logic from their usage. <br />
    This is required when we may have to create similar objects 
    <br />
    which follow the same interface or are subclasses of same superclass <br /> <br />
    Some example are :  <br /> 
      - Creating different pieces in chess e.g. pawn, king, queen etc. <br />
      - Creating different type of cars in a car race video game. <br />  
      
  </div>
 { <div className=' flex flex-col mt-4 ml-6'>
  <YoutubeVideoDisplay videoId="fLiMD-leeag" />
   
  <YoutubeVideoDisplay videoId="B8wmPFBKOgk" />
  
 </div>
}
  </div>  {/*factory row ends */}
<br />
  {/* Also need a row for factory pattern vs singleton pattern comparison videos */}

  {/*singleton row starts */}  
  <div className='flex flex-row items-center justify-center'>

 <div className=' flex flex-row mt-4 mr-6'>
 { <div className=' flex flex-row mt-4 ml-6'>
  <YoutubeVideoDisplay videoId="MCvV_MLJ0Z0" />
   
  <YoutubeVideoDisplay videoId="htTtnBcAJxE" />
  
 </div>
}

  <div className='ml-6'>
    {txhbb('Singleton Design Pattern')} <br /> <br />

    Singleton Pattern is used when we need a 
    <br /> single instance of an object. <br />
    e.g. A connection pool manager for managing <br /> database connection will be a singleton.  <br />
    Generally factories are implemented as a singleton <br />    
  </div>
  
  
 </div>
  </div>  {/*singleton row ends */}
  <br />
  {/* practice problem row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design Chess Game")} <br />
  <LinkUI url="https://codezym.com/question/8" />  <br /><br />
  In Low Level Design of chess we use following design patterns <br />
   1. Chess Piece Factory to create different chess piece objects like king, queen, pawn etc <br />
   2. Strategy pattern to implement different moves e.g. straight move, diagonal move etc.  <br />
    3. Singleton pattern to ensure there is a single instance of chess piece factory object.
   <br /> <br />
  </div>
  <div><YoutubeVideoDisplay videoId='InV_3ZZJF30' /></div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

function DayFlyweightPattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  
  {/*factory row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mr-6'> <br />
    {txhbb('Flyweight Design Pattern')} <br /> <br />

    Flyweight design pattern is used when we need to
    <br />   create a large number of 
    similar objects  <br /> 
    It helps us save memory space by sharing  <br /> 
    constant state among objects.
     <br /> <br />
     &nbsp; Some examples are <br />
      - Sharing 3-d images of thousands of soldiers <br />
      &nbsp; in a computer war game
       <br />
      - Sharing styles of characters in a 
      text editor <br /> &nbsp; like Microsoft word.
       <br />  
      
  </div>
  <div className=' flex flex-row mt-4 ml-6'>
  <YoutubeVideoDisplay videoId="ca82h-4c72g" 
  />
  <YoutubeVideoDisplay videoId="uAcwcecAkDA" 
  />
  
 </div>
  </div>  {/*flyweight row ends */}
<br />
  {/* Also need a row for flyweight pattern vs factory pattern vs singleton pattern comparison videos */}

  {/* practice problem row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design a Text Editor/Word Processor like Microsoft Word")} <br />
  <LinkUI url="https://codezym.com/question/9" />  <br /><br />
  In a text editor, each character can have a different style <br />
   in terms of parameter like colour, font size etc <br />
    <br />
   Also there can be millions of characters in a text editor file,  
   <br />
   but total number of different styles used will be just from <br />
    a few 100's to a few 1000's <br />  <br />
   So it makes sense to share character style as constant intrinsic state <br />
   and reduce memory footprint of program <br />
  </div>
  <div className='flex flex-col justify-center items-center mx-12'>
  <div><YoutubeVideoDisplay videoId='QG0OuN-57ks' /></div>
  <br />
  <div><YoutubeVideoDisplay videoId='hmQw5DfiRws' /></div>
  </div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

function DayObserverPattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  
  {/*factory row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mr-6'> <br />
    {txhbb('Observer Design Pattern')} <br /> <br />

    Observer Pattern is a behavioural design pattern and  <br /> 
    it is used when you want to have loose coupling <br /> between data which is getting changed and <br /> multiple views or observers which need to receive the changes. <br /> <br /> 
    {txb('example:')} Giving 1 to 5 star rating to a product on ecommerce website <br />needs to be observed by the class which shows list of top rated products.
    <br /> <br />
    Observer pattern is generally used along with {txb('strategy pattern')}
     <br />  to inform different strategy classes about <br />  changes occurring in the common data structure. <br /> <br /> 
     {txb('example:')} a customer support system may have different strategy classes
    <br />   to assign an agent to a customer support call.<br />
    Also each  strategy need to be informed whenever <br />  an agent is assigned a case so that they can decide <br /> whether to assign new cases to this agent or not. <br /> 
    Observer pattern will be used in this case.
    
    <br /> <br /> 
  </div>
  <div className='flex flex-col mt-4 ml-6'>
  <YoutubeVideoDisplay videoId="RFz6SCx0KF8" 
  />
  <br /> <br />
  <YoutubeVideoDisplay videoId="wsJ--Ln6QsI" 
  />
 </div>
  </div>  {/*factory row ends */}
<br />
  {/* video for strategy and observer design pattern used together */}

  {/* practice problem row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design a food ordering and rating system like ")} <br />
  &nbsp; {txb("Zomato, Swiggy,  DoorDash")} <br />
  <LinkUI url="https://codezym.com/question/5" />  <br /><br />
  This system will have functionality to order food, rate orders <br />
  and list top restaurants based on their rating <br /><br />
  Now whenever an order is rated then it has to observed <br />
  by classes which return top restaurants lists based on their rating. <br />
  So this is an ideal use case for {txb('observer design pattern')} 
  <br /> <br /> 
    Watch {txbb("Java")} and {txbb("Python")} video solutions on the right for explanation.
   <br /> <br />
  </div>
  <div><YoutubeVideoDisplay videoId='v9ehOtY_x7Q' /></div> 
  &nbsp; &nbsp;
  <div><YoutubeVideoDisplay videoId='KGN-pSlMZgg' /></div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

function DayStatePattern(){
  return (
  <div className='flex flex-col w-full 
  items-center justify-center'>
  {/* later add question and videos on atm machine and vending machine */}
  {/*state row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mr-6'> <br />
    {txhbb('State Design Pattern')} <br /> <br />

    State is a behavioural design pattern and it is used when <br /> 
    an object has to change its behaviour as its internal state changes. 
     <br />  <br /> 
    {txb('For example')} an ATM machine may be in different states  <br />  
    like idle, money withdrawal in progress, validating pin etc  <br /> 
     and will respond to keyboard button presses differently in each state.  <br /> 
    The same keyboard can be used to input user's PIN as well as  <br />  mobile number,
    depending on the current state of ATM. 
    <br /> <br /> 
    {txb('another example')} is of a Cab booking System <br />
    Ride can be in states like:<br />
      Ride Requested, Ongoing, Finished, Cancelled <br />
      which will have multiple states and in each of these states <br /> 
       calculation for a common behaviour like <br /> 
      {txb(' timeTakenToReachDestaination()')} will be different.
   
    <br /> <br /> 
  </div>
  <div className='flex flex-col mt-4 ml-6'>
  <YoutubeVideoDisplay videoId="PsDVcvAhU5s" 
  />  <br />
  <YoutubeVideoDisplay videoId="H6xLTb_ktbU" 
  />
  
 </div>
  </div>  {/*state row ends */}

<br />
 
  {/* practice problem row starts */}  
  <div className='flex flex-row items-center justify-center'>
  <div className='mx-12'>{txbb("Practice Problem")}  <br />
  {txb("Design an elevator management System with multiple lifts")} <br />
  <LinkUI url="https://codezym.com/question/2" />  <br /><br />
  A lift in an elevator system can be in one of three states.  <br />
  {txb('Moving Up,')} {txb('Moving Down')} and {txb('Idle')}   <br />
  And in each state it will behave differently in taking decisions like  <br />
  whether to stop on a floor, add a new request or not etc  <br />
  So we will use {txb('state design pattern')} to solve this problem.
   <br /> <br />
  </div>
  <div><YoutubeVideoDisplay videoId='_2Vpjniu5fM' /></div>
  </div>  {/* practice problem row ends */}

  </div>
  );
}

export {FirstPreparationPlan}