import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, getPremiumPageDiscount, getUIState, getUser, isValidDiscount } from "../login/auth_utils";
import { dateString, homeUrl } from "../utils/common_utils";
import { appendJSDependency, getJsonDataPostRequest, removeJSDependency } from "../utils/network_utils";
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import { firstWeekDaysLeft } from "./payment_utils";


/**
 * @returns generic one line component with go to premium button
 */
function GenericPremiumOneLineComponent({discount=getPremiumPageDiscount()}){
 // console.log("discount object "+JSON.stringify(discount))
  if(!isValidDiscount(discount)) return (<span></span>)
  return (
    <div className="text-center flex-row 
      text-sm text-gray-400 ">
        <a href="/premium" className="
          text-sm font-sans rounded-full  
             hover:text-gray-700 ">
      <span className="font-medium ">
        <span className=""> {discount.oneLineDescription}
        </span>

        { discount.discountPercentage>0 &&
        <span className="text-xl text-orange-600">
         &nbsp; {discount.discountPercentage}% off </span>
        }

        {discount.timeLeftString && discount.timeLeftString.length>0 &&
        <span> &nbsp; {discount.timeLeftString}  
        </span>
        }
          
          <span>
            {/* <a href="/premium" className="ml-2 p-2
          text-sm font-sans rounded-full
           text-white bg-orange-500 
            hover:bg-blue-600"> Premium</a>
  */}
          </span>
         
      </span>
      </a>  
    </div>
  )
}


/**
 * This function will be used to show a generic buy premium button 
 * data for showing in UI will be fetched from backend and saved in local storage
 * with one line discount and Buy Premium button which will take you to premium page
 */
function VisitPremiumSection({discount=getPremiumPageDiscount()}){
  const uiState=getUIState()
  const daysLeftString=firstWeekDaysLeft()
  const goToPaymentPage = (e) => {
    e.preventDefault();
    window.location.href='/premium'
  };
 return (
  <div className="flex flex-col mt-2 items-center text-center justify-center 
  text-lg font-medium text-gray-700">
    { isValidDiscount(discount) &&
      <div >
      {discount.oneLineDescription}  {discount.discountPercentage}% Discount</div>
    }
    
    { discount.timeLeftString && discount.timeLeftString.length>0 &&
      <div className="font-medium text-green-600 mt-2">
        {discount.timeLeftString}</div>
    }
   
  <div className="mt-6">
    <button
      onClick={goToPaymentPage}
      className="text-xl font-medium  rounded-full
        text-white  bg-blue-600
        px-4 py-4 hover:bg-orange-600" 
    >
    Premium
    </button>
  </div>
 </div>
  
);
}

/**
   * gives UI with generic 70% or 60% discount
   */
function BuyPremium() {
 // discountState=1
  const [error, setError] = useState('')
  const [showProgressBar, setShowProgressBar]=useState(false)
  const[paymentUrl, setPaymentUrl]=useState('')
  const user=getUser()
  const auth = getAuth()
//  const navigate = useNavigate();

 // appendJSDependency(document,  'https://d2xwmjc4uy2hr5.cloudfront.net/im-embed/im-embed.min.js')
  //console.log(user);

  const makePayment = (e) => {
    e.preventDefault();
    let url = homeUrl()+"paymentsApi/createOrGetPaymentRequest";
      getJsonDataPostRequest(url, getAuth(), function(response){
        setShowProgressBar(false);
        if(response==null||response.status>=400){
           setError(response.message | "Some error occured, please try again") 
           return    
        }
        setPaymentUrl(response.paymentUrl)
        window.location.href=response.paymentUrl;
        // sample payment url
        // https://www.instamojo.com/@codezym/538686dc727d448a8c30d3cf8eac36ac?embed=form
    })
    
  };

  function initializePaddle(){
    //window.Paddle.Setup({ vendor: '198825'});
    const SANDBOX_TOKEN="test_ca5d9b77b82ea9f246ecafc41a1"
    const PRODUCTION_TOKEN="live_7bc5cd922b7acb35a7a4cb41544"
    window.Paddle.Environment.set('production') //'sandbox' 'production'
    window.Paddle.Initialize({
      token: PRODUCTION_TOKEN, // replace with a client-side token
      eventCallback: function(data) {
        //console.log("Paddle event occured "+JSON.stringify(data))
        if (data.name == "checkout.completed" ||
          data.name=="checkout.loaded"  
          // || data.name=="checkout.closed"
        ) {
          sendPaymentDataToBackend(data,()=>{
            if(data.name=="checkout.completed"){
              window.location.href='/premium'
            }
          })
        }
      }
    });
    console.log("paddle has been initialized with a client side token")
  }

  const makePaddlePayment = (e) => {
    e.preventDefault();
    console.log("inside paddle payment")
    // define items
    const SANDBOX_PRICE_ID="pri_01j5xbsbsxz9zpb3y2z550r43v"
    const PRODUCTION_PRICE_ID="pri_01j5w7r3t53znqddkj9edzzm1v"
    let itemsList = [
      {
        priceId: PRODUCTION_PRICE_ID,
        quantity: 1
      }
    ];
    // card number is 4242 4242 4242 4242 , rest details as you wish
    window.Paddle.Checkout.open({
      items: itemsList,
      customer: {
        email: user.email,
      },
      customData: {
        userId: ""+user.userId,
        email: user.email,
      },
    });
  };

  function sendPaymentDataToBackend(jsonData, callback) {
    let url = homeUrl()+"paymentsApi/paddle/paddlePaymentFrontendData";
    var data={
      auth:getAuth(),
      json:JSON.stringify(jsonData)
    }
    getJsonDataPostRequest(url, data, function(response){
      callback()
      if(response==null||response.status>=400){
          console.log(response.message | "Some error occured, please try again")    
      }
  })
    
  };

  useEffect(() => {
    const paddleSrc= 'https://cdn.paddle.com/paddle/v2/paddle.js';
    let user=getUser()
    setShowProgressBar(true)
    appendJSDependency(document, paddleSrc, ()=>{
      setShowProgressBar(false)
      console.log("paddle dependency is added")
      initializePaddle()
    })
    
    // Cleanup function
    return () => {
      removeJSDependency(document, paddleSrc);
    };
  }, []); // Empty dependency array ensures this effect runs only once after mount


  return (
    <div className="flex items-center justify-center">
    {
      paymentUrl && paymentUrl.length>5 &&
      <div>
        <a href={paymentUrl} rel="im-checkout" data-behaviour="remote" data-style="light" data-text="Checkout With Instamojo"></a>

      </div>
    } 

    { (!paymentUrl || paymentUrl.length<5) &&
    <div className="pt-4">
    <div className=" flex items-center justify-center">
    
    {showProgressBar &&
    <div className=" mt-32  z-50">
      <IndefiniteProgressBar />  </div> 
    }

    {error && error.length>0 &&
    <div className="text-lg font-semibold text-red-700">
    {error} <br /> 
    </div>
    }
    
     
    </div>
   
    <GenericDiscount />
    <br />
    <div className="flex flex-row items-center justify-center">
    <div className="flex flex-col px-8 py-4 items-center justify-center
    rounded-lg border-2 border-gray-900 text-center text-xl">
   {  <div className=""> For customers from India <br /> 
     <span className="text-sm text-gray-600"> (UPI available) </span></div>
    }   
    <button
        onClick={makePayment}
        className="  text-xl font-medium rounded-all
          text-white  bg-blue-600
          px-4 py-4 mt-6  hover:bg-orange-600">
        Buy Premium
      </button>
      <br />
      <div className="w-full text-center text-gray-600 text-sm">
      You will be redirected to 
      <span className="text-blue-600 font-medium"> Instamojo </span> for payment</div>
    </div>
    &nbsp;&nbsp;
    {/* international payment section starts */}
   { <div className="flex flex-col m-8 px-8 py-4 items-center justify-center
    rounded-lg border-2 border-gray-800">
     <div className="text-center text-xl"> For <span className="font-bold  text-blue-600">International  </span> customers  <br />
      <span className="text-gray-600 text-sm"> US/China/Europe/Russia/UK etc </span> </div>
    <button
        onClick={makePaddlePayment}
        className="  text-xl font-medium rounded-all
          text-white  bg-purple-600
          px-4 py-4 mt-6  hover:bg-orange-600">
        Buy Premium
      </button>
      <br />
    {
      <div className="w-full text-center text-gray-600 text-sm">
      You will be charged 
      <span className="text-blue-600 font-medium"> USD 29, </span> 
      valid for 365 days </div>
    }
    </div>
   }
   </div>
    
    <br />
    <br />
    </div>
    }

    </div>
  );
}

/**
 * The UI, image and everything shown in this component
 * will be fetched from backend and stored in local storage
 */
function GenericDiscount({discount=getPremiumPageDiscount()}){
  if(!isValidDiscount(discount)) return FiftyOffDiscount()
    discount.multiLineDescription=discount.multiLineDescription.split(",,")
  return (
    <div className="left-0  bg-white flex justify-center items-center  ">
      <div className="w-1/2 h-3/4 bg-white rounded-lg shadow-md overflow-auto">
        <div className="flex justify-between items-center px-4">
          <div className="w-full md:w-3/5 border-gray-800">
            {/* Your text content here */}
            <div className=" font-medium text-gray-700
       bg-white border-spacing-1 rounded-lg p-16 mt-6 text-center ">
      
          <span className="text-3xl 6">{discount.multiLineDescription[0]}</span> 
          <br />
          <div className="m-8">
          <span className="text-gray-400 text-lg">
            {discount.multiLineDescription[1]}</span>  <br />
        
          <span className="text-4xl mt-4">
          <span className="text-green-600 font-bold">
            {discount.discountPercentage}% Off </span>
          </span><br />
          </div>
          <div className="text-center text-2xl space-x-2 mt-2">
          <span className="text-blue-500 font-bold">
            Rs {discount.finalAmount}/-</span>
          &nbsp;
          <span className="text-gray-500 line-through decoration-orange-500 decoration-double">Rs {discount.crossedAmount}/-</span>
          <br /><br />
         <span className="text-sm text-gray-400">
          Validity  : 365 days</span> 
         </div>
      </div>
            
          </div>
          <div className="w-full md:w-1/2 flex justify-end">
            <img
              className="rounded-lg object-cover h-full w-full"
              src={discount.thumbnailUrl}
              alt="Discount Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

  function FiftyOffDiscount(){
    return (
      <div className="left-0  bg-white flex justify-center items-center mb-16 ">
        <div className="w-1/2 h-3/4 bg-white rounded-lg shadow-md overflow-auto">
          <div className="flex justify-between items-center px-4">
            <div className="w-full md:w-3/5 border-gray-800">
              {/* Your text content here */}
              <div className=" font-medium text-gray-700
         bg-white border-spacing-1 rounded-lg p-16 mt-6 text-center ">
        
            <span className="text-3xl 6">Special Offer</span> 
            <br />
            <div className="m-8">
            <span className="text-gray-400 text-lg">
              Get Premium at</span>  <br />
          
            <span className="text-4xl mt-4">
            <span className="text-green-600 font-bold">50% Off</span>
             </span><br />
            </div>
            <div className="text-center text-2xl space-x-2 mt-2">
            <span className="text-blue-500 font-bold">Rs 2000/-</span>
            &nbsp;
            <span className="text-gray-500 line-through decoration-orange-500 decoration-double">Rs 4000/-</span>
            <br /><br />
           <span className="text-sm text-gray-400">
            Validity  : 365 days</span> 
           </div>
        </div>
              
            </div>
            <div className="w-full md:w-1/2 flex justify-end">
              <img
                className="rounded-lg object-cover h-full w-full"
                src="https://public-items-1.s3.ap-south-1.amazonaws.com/codezym/payment_images/discount-50-img-8.jpeg"
                alt="Discount Image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function FirstWeekDiscount(){
    return (
      <div className="left-0  bg-white flex justify-center items-center mb-16 ">
        <div className="w-1/2 h-3/4 bg-white rounded-lg shadow-md overflow-auto">
          <div className="flex justify-between items-center px-4">
            <div className="w-full md:w-3/5 border-gray-800">
              {/* Your text content here */}
              <div className=" font-medium text-gray-700
         bg-white border-spacing-1 rounded-lg p-16 mt-6 text-center ">
        
            <span className="text-3xl 6">First Week Offer</span> 
            <br />
            <div className="m-8">
            <span className="text-gray-400 text-lg">
              Get Premium at</span>  <br />
          
            <span className="text-4xl mt-4">
            <span className="text-green-600 font-bold">75% Off </span>
            </span><br />
            </div>
            <div className="text-center text-2xl space-x-2 mt-2">
            <span className="text-blue-500 font-bold">Rs 1000/-</span>
            &nbsp;
            <span className="text-gray-500 line-through decoration-orange-500 decoration-double">Rs 4000/-</span>
            <br /><br />
           <span className="text-sm text-gray-400">
            Validity  : 365 days</span> 
           </div>
        </div>
              
            </div>
            <div className="w-full md:w-1/2 flex justify-end">
              <img
                className="rounded-lg object-cover h-full w-full"
                src="https://public-items-1.s3.ap-south-1.amazonaws.com/codezym/payment_images/discount-75-img-7.jpeg"
                alt="Discount Image"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }


export {BuyPremium, VisitPremiumSection, GenericPremiumOneLineComponent};