import React, { useState, useEffect } from 'react';
import { errorMsg, homeUrl, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import {  getUIState, isPremiumUser, refreshHomePageDetails } from '../login/auth_utils';
import { LockIcon, UnlockIcon } from '../ui_components/common_icons';
import { GenericPremiumOneLineComponent, PremiumOneLineComponent } from '../premium/premium_discounts';
import { PreparationLinkHomePage } from '../preparation_plans/preparation_home_page_link';
import { DoNotLikeCodeZymFormButton } from '../customer_support/do_not_like_codezym';
import { MockInterviewTopmateHomePage } from './mock_interview';
import { LinkUI } from '../ui_components/common_links';


function AllQuestionsTable() {
  const [questionList, setQuestionList] = useState([]);
  const [message, setMessage] = useState('');
  const[error, setError] = useState('')
  const isPremium=isPremiumUser();
  const uiState=getUIState()
  console.log("going to build all questions table : ")
{/*
  useEffect(() => {
    setQuestionList(questions);
  }, [questions]);
*/}

useEffect(() => {
    console.log("fetching the all questions list");
    fetch(homeUrl()+"questionsApi/allQuestions", {
      method: "GET"
    })
      .then((response) => response.json())
      .then((data) => {
        if(success(data.status)){
          setQuestionList(data.questions)
        }
        else{
          setError(data.message || "Error in fetching questions, please try again")
        }
          console.log("response received")
          console.log(data);
          refreshHomePageDetails()
      })
      .catch((error) => {
        console.log("error occured in fetching all questions "+JSON.stringify(error));
        setError(errorMsg())
      });  
}, []); // empty bracket means there are no dependencies which will make useEffect re run
// Talk is cheap, show me the code
  return (
    <div className = "flex flex-col w-screen items-center  justify-center ">
      { // hiding the do not like codezym form for now as python is being added now, will bring it back up with new options when launch of ai mock interviewer is going to be there.
      false && <DoNotLikeCodeZymFormButton />
      }
    {
      <div className="mb-4">
       <GenericPremiumOneLineComponent />
      </div>
     } 
     { <p className="px-4 py-2 mt-2 font-mono text-center text-gray-700">

A Bad Low Level Design Round == <span className='text-red-600 font-bold text-xl '>No-Hire</span> <br />
A Good Low Level Design Round == <span className='text-blue-600 font-bold text-xl '>Salary Hike++</span>
<br /> 
<hr className='mt-2'/>
<div className='text-gray-700 font-medium py-4'>Practice Top Low Level Design Interview Questions using Design Patterns </div>
<br /> <br />
  
</p>
}

{
/*
<span className='text-blue-600 font-bold text-xl '> 30% </span> salary hike becomes <span className='text-blue-600 font-bold text-xl '>50%++ </span>
*/
/* Doing well in a LLD round means your 30% salary hike becomes 50% or more
Practice Machine Coding of Low-Level Design Interview questions
<br />
Good performance in LLD Interview round means your 30% salary hike becomes 50% or more

Practice Machine Coding of Low-Level Design Interview Questions in a Multi-Threaded Environment

Writing correct programs is hard; writing correct concurrent programs is harder
*/}    
    

   
    {
      <div className="flex flex-row space-x-8">
       <MockInterviewTopmateHomePage />
          
      <PreparationLinkHomePage />
      </div>
     }     
    {questionList.length>0 &&  <table className="table-auto border-collapse w-2/3">

      <thead>
        <tr className="text-gray-700 bg-white">
       { !isPremium && // shows lock/unlock icons when user is not premium
       <th className="px-2 py-3  text-left pl-6  text-gray-400 font-normal ">   </th>
      }
        <th className="px-2 py-3  text-left  text-gray-400 font-normal ">
            Title
          </th>
          <th className="px-2 py-3  text-left  text-gray-400 font-normal ">
            Difficulty
          </th> 
          <th className="px-2 pl-6 ml-6 py-3  text-left  text-gray-400 font-normal ">
            Design Pattern
          </th> 
        </tr>
      </thead>
      
      <tbody className='p-20 font-normal'>
        {questionList.map((question) => (
          <tr key={question.questionId} className="border-b border-gray-200 hover:text-blue-700
          even:white odd:bg-gray-100">
           { // show lock/unlock icons column only if user is not premium else hide it.
           !isPremium && <td className=" pl-6 py-3">
            { question.type==1 && !isPremium &&
            <LockIcon />
            } 
            {(question.type!=1 || isPremium) && 
            <UnlockIcon />
            }  
            </td>
          }
            <td className="px-4 pl-1 py-3">
             <span className="px-4 text-sm text-gray-500">
              {question.questionId}. &nbsp; </span>
              <a href={`/question/${question.questionId}`} className="text-black hover:text-blue-600">{question.heading}</a>
            </td>

            <td>
              <div className={`text-lg ${
                question.difficulty === 1 ? 'text-orange-500' :
                question.difficulty === 2 ? 'text-red-600' :
                'text-green-500'
              }`}>
                {question.difficulty==1?'Medium':
                question.difficulty==2?'Hard':
                'Easy'}
              </div>
            
            </td>
            <td className="px-2 pl-6 ml-6 py-3 text-gray-500">
              {removeAfterComma(question.designPatterns)}
              </td>    
          </tr>
        ))}
      </tbody>

    </table>
  }
 
{!error && !message && questionList.length==0 &&
<div className="text-2xl font-bold text-blue-700">
  Loading all Questions. <br /> Please wait ...
</div>
}

{!error && questionList.length==0 &&
<div className=" mt-32  z-50">
  <IndefiniteProgressBar />  </div> 
}

{error && error.length>0 &&
<div className="text-lg font-semibold text-red-700">
 {error} <br /> 
</div>
}

{message && message.length>0 && !error && error.length==0 &&
<div className="text-xl font-bold text-blue-700">
 {message} <br /> 
</div>
}

<div className="text-sm text-gray-500 text-center">
<br /> <br />
<a 
href = "https://www.linkedin.com/in/prashant-priyadarshi-2705334a/" target="_blank">
For discussions/suggestions, feel free to connect with me on 
&nbsp;
 <span className="text-blue-600 decoration-underline">LinkedIn</span></a>
<br />
Reach us at : &nbsp; 
<span className="text-blue-600">contact@codezym.com</span><br /><br /><br /><br /><br />
<div className='flex flex-row  
          text-center justify-center items-center'>
   
      <img className='w-1/2 '   
          src="https://public-items-1.s3.ap-south-1.amazonaws.com/codezym/seo_images/codezym-home-page-2.jpg"
      />
    </div> <br /><br />

</div>

<div className='flex flex-row items-center justify-center m-40'>
  <LinkUI url="/terms" text="Terms and Conditions" />
  &nbsp; &nbsp; 
  <LinkUI url="/privacy" text="Privacy Policy" />
</div>
<br /><br />   
</div>
  );
        
}

function removeAfterComma(text){
 if(text==null||!text||text.length==0) return ''
 let index=text.indexOf(',')
 if(index<0) return text
 return text.substring(0, index)
}

export default AllQuestionsTable;

