import React, { useState, useEffect, Children } from 'react';
import { errorMsg, homeUrl, isBlankOrNullString, rearrangeArray, success } from '../utils/common_utils';
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import {NavbarTop} from '../ui_components/navbar_top';
import { YoutubeVideoDisplay } from '../ui_components/youtube_video_display';
import { LinkUI, LinkUIBasic, LinkUIBlueBold } from '../ui_components/common_links';
import { txb, txbb, txhb, txhbb } from '../ui_components/text_style_components';
import { ExpandCloseTab } from '../ui_components/common_ui_components';
import { getPremiumPageDiscount, isPremiumUser, isValidDiscount } from '../login/auth_utils';
import { VisitPremiumSection } from '../premium/premium_discounts';


function BuyPremiumMotivationSmall1(){
  
  return (
    <div className="flex flex-col justify-center mt-16 
    font-medium text-center
    bg-white  m-12">
    <div className='text-2xl text-gray-500'> 
      You need to be a Premium Member <br />
      to attempt this question
    </div>
    <br />  <br />
    <div className='font-mono bg-yellow-100 
    rounded-lg px-2 py-4 text-gray-800  '>
        {LineText1("Practice this Question,", "italic")}
        {LineText1("You will be Ahead of Other Candidates", 
          "font-normal font-semibold text-xl text-blue-600")}
        {LineText1("Who Only Watched Video Tutorials", "italic")}
       {/*
        {LineText("Practicing this question")}
        {LineText("Will give You its in-depth understanding")}
        {LineText("It's better than watching even tens of video tutorials")}
      */} 
    </div>
    <br />  <br /> 
    <VisitPremiumSection />
    </div>
  );
}

function LineText1(text, className=""){
  if(isBlankOrNullString(text)) return (<span></span>);
  return (<div className={`${className}  m-2`}>
     {text} 
     
     </div>);
 }

function BuyPremiumMotivationQuestionsPage(){
    const [premiumPrice, setPremiumPrice] = useState(800);
    useEffect(() => {
        let discount = getPremiumPageDiscount()
        //console.log(discount)
        if(isValidDiscount(discount))
            setPremiumPrice(discount.finalAmount)

    }, []); // empty bracket means there are no dependencies which will make useEffect re run  
  return(
    <div className='flex flex-col justify-center items-center text-center font-mono font-medium text-xl'>
        <br /> <br /><br /><br />
        {LineText("You are here")}
        {LineText("You want to Practice this Question")}
        {LineText("That would be a wise choice")}
        {LineText("One with Long Term Benefits")}

        <div className='text-3xl py-8 text-red-600 font-bold'>
            But </div>
        <div>This is a 
             <LinkUIBlueBold url= "/premium" text="Premium" />  Question </div>    
        {LineText("Buying Premium will cost you Rs "+premiumPrice.toString() +"/- for an Year")}

        <div className='text-3xl py-8 text-red-600 font-bold'>
            More than that </div>

        {LineText("Implementing Code for this or any other question")}
        {LineText(" will take at least 3-4 hours")}
        {LineText("maybe even a day")}
        {LineText("There will be small failures")}
        {LineText("and moments of sadness")}
        {LineText("Your code may fail test cases many times")}
        
        <div className='text-3xl py-8 text-green-600 font-bold'>
        But </div>

        {LineText("Those small failures will lead to")}
        {LineText("an in depth understanding of question")}
        {LineText("watching even hundreds of video tutorials won't match it")}

        <div className='text-3xl py-8 text-green-600 font-bold'>
        Soon,  </div>
        {LineText("The tests will start to pass")}
        {LineText("And you will have the confidence to clear interviews")}

        <div className='text-3xl py-8 text-green-600 font-bold'>
        You </div>
        {LineText("Deserve your dream Job")}
        {LineText("And that dream package")}
        {LineText("And the respect that comes with it")}
        
        <div className='text-2xl pt-6 py-2 text-red-600 font-bold'>
            Don't Overthink</div>

    {LineText("Take the first step")}
        
        <br />

        <a href="/premium"
        className="text-white bg-blue-600
        cursor-pointer px-6 py-4 rounded-full
        hover:bg-blue-700
        hover:underline
        font-medium font-mono">
          Buy Premium    
        </a>
        <br />



        <br /><br /><br /><br />
        <br /><br /><br /><br />
    </div>
  ); 
}

function LineText(text){
 if(isBlankOrNullString(text)) return (<span></span>);
 return (<div className=' font-mono font-normal m-2'>
    {text} 
    
    </div>);
}

export {BuyPremiumMotivationQuestionsPage, BuyPremiumMotivationSmall1}